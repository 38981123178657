import { useEffect, useState } from 'react';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import { LanguageData } from '@hultafors/shared/types';

import PlusSvg from '../../svg/plus.svg';
import { Accordion } from '../accordion/accordion';
import {
  AccordionItem,
  AccordionItemClassNames,
} from '../accordion-item/accordion-item';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Markdown } from '../markdown/markdown';

import { BrandLink, FooterMenuItem, SocialMediaMenuItem } from './footer-types';
import styles from './footer.module.scss';

interface FooterProps {
  activeMarket?: LanguageData | null;
  bottomIntro?: string;
  brandLinks?: BrandLink[];
  className?: string;
  cookieSettingsLabel?: string | null | undefined;
  introText?: string;
  items: FooterMenuItem[];
  logoAriaLabel: string;
  logoIcon: React.ReactNode;
  moreInfoText?: string;
  selectCountryLabel?: string;
  socialMediaLabel?: string | null | undefined;
  socialMediaMenu?: SocialMediaMenuItem[];
  toggleMarketPicker: () => void;
}

export const Footer = ({
  activeMarket,
  bottomIntro,
  brandLinks,
  className,
  cookieSettingsLabel,
  introText,
  items,
  logoAriaLabel,
  logoIcon,
  moreInfoText,
  selectCountryLabel,
  socialMediaLabel,
  socialMediaMenu,
  toggleMarketPicker,
}: FooterProps) => {
  const [marketName, setMarketName] = useState<string>();

  const handleOnPressSettings = () => {
    // TODO fix types for window
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  const onClickCookieSettings: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    handleOnPressSettings();
  };

  function brandLinksMapper(link: BrandLink, index: number) {
    if (!link?.url || !link?.title) {
      return null;
    }
    return (
      <Link
        href={link.url ?? ''}
        key={`Company-${index}`}
        className={styles['link']}
        target="_blank"
      >
        {link.title}
      </Link>
    );
  }

  const DesktopMenuLinks: React.FC<Pick<FooterProps, 'items'>> = ({
    items,
  }) => {
    if (!items) {
      return null;
    }

    return items.map((item, index) => {
      if (!item.links) return null;
      return (
        <div
          key={`desktop-menu-${item.heading}-${index}`}
          className={styles['desktop-menu']}
        >
          <div className={styles['desktop-menu-item']}>
            <span className={styles['desktop-menu-title']}>
              {item?.heading}
            </span>
            {item.links?.map((link, subIndex: number) => {
              let hrefTarget = '_self';
              if (link.url?.includes('https') || link.url?.includes('www')) {
                hrefTarget = '_blank';
              }
              return (
                <Link
                  href={link.url ?? ''}
                  target={hrefTarget}
                  key={`${link.title}-${subIndex + index}`}
                  className={styles['link']}
                >
                  {link.title}
                </Link>
              );
            })}
            {item?.includeCookieToggle && (
              <button
                aria-label={cookieSettingsLabel ?? 'Cookies'}
                onClick={onClickCookieSettings}
                className={clsx(styles['cookie-settings-btn'], styles['link'])}
              >
                {cookieSettingsLabel}
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  const MobileMenuLinks: React.FC<Pick<FooterProps, 'items'>> = ({ items }) => {
    if (!items) {
      return null;
    }

    const accordionItemClassNames: AccordionItemClassNames = {
      trigger: styles['accordion-trigger'],
    };

    return (
      <Accordion className={styles['mobile-menu-accordion']}>
        {items.map((item, index) => {
          if (!item.links) return null;
          return (
            <AccordionItem
              key={`accordion-menu-${item.heading}-${index}`}
              label={item.heading}
              value={`accordion-${item.heading}-${index}`}
              indicator={<PlusSvg aria-hidden={true} focusable={false} />}
              classNames={accordionItemClassNames}
            >
              <div className={styles['accordion-content-wrapper']}>
                {item.links.map((link, subIndex: number) => {
                  let hrefTarget = '_self';

                  if (!link?.url) {
                    return null;
                  }

                  if (
                    link.url?.includes('https') ||
                    link.url?.includes('www')
                  ) {
                    hrefTarget = '_blank';
                  }
                  return (
                    <Link
                      key={`${link.title}-${subIndex + index}`}
                      href={link.url}
                      target={hrefTarget}
                      className={styles['link']}
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>
              {item?.includeCookieToggle && (
                <button
                  aria-label={cookieSettingsLabel ?? 'Cookies'}
                  onClick={onClickCookieSettings}
                  className={clsx(
                    styles['cookie-settings-btn'],
                    styles['link'],
                  )}
                >
                  {cookieSettingsLabel}
                </button>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  function socialMediaMapper(item: SocialMediaMenuItem, index: number) {
    if (!item.url) {
      return null;
    }

    return (
      <Link
        key={`SocialMedia-${index}`}
        href={item.url ?? ''}
        aria-label={item.icon?.title ?? ''}
        target="_blank"
        rel="noopener noreferrer"
        className={styles['social-icons-link']}
      >
        <Image alt={item?.icon?.alt ?? ''} src={item?.icon?.url ?? ''} fill />
      </Link>
    );
  }

  useEffect(() => {
    if (!activeMarket?.name) {
      return;
    }
    setMarketName(activeMarket.name);
  }, [activeMarket?.name]);

  return (
    <footer className={clsx(styles['footer'], className)}>
      <div className={styles['outer']}>
        {logoIcon && (
          <Link
            href="/"
            aria-label={logoAriaLabel}
            className={styles['logo-link']}
          >
            {logoIcon}
          </Link>
        )}
        <Grid
          columnGap={50}
          rowGap={50}
          columns={[{ columns: 1 }]}
          className={styles['grid']}
        >
          <GridChild
            className={styles['left-section']}
            columnSpan={[{ breakpoint: 'desktopSmall', columns: 1, start: 1 }]}
          >
            {bottomIntro && (
              <span className={styles['bottom-intro']}>{bottomIntro}</span>
            )}
            <div>
              {introText && (
                <Markdown
                  options={{ forceBlock: true }}
                  className={styles['markdown']}
                >
                  {introText}
                </Markdown>
              )}
              {brandLinks && (
                <nav className={styles['brand-links']}>
                  {brandLinks.map(brandLinksMapper)}
                </nav>
              )}
            </div>
            {moreInfoText && (
              <Markdown className={styles['markdown']}>{moreInfoText}</Markdown>
            )}
            {socialMediaMenu && (
              <div className={styles['social-media-section']}>
                <div className={styles['social-media-label']}>
                  {socialMediaLabel}
                </div>
                <div className={styles['social-icons-wrapper']}>
                  {socialMediaMenu.map(socialMediaMapper)}
                </div>
              </div>
            )}
          </GridChild>
          <GridChild
            className={styles['right-section']}
            columnSpan={[{ breakpoint: 'desktopSmall', columns: 1, start: 2 }]}
          >
            {items && (
              <>
                <MobileMenuLinks items={items} />
                <DesktopMenuLinks items={items} />
              </>
            )}
            {selectCountryLabel && marketName && (
              <div className={styles['footer-end-container']}>
                <span className={styles['desktop-menu-title']}>
                  {selectCountryLabel}
                </span>
                <button
                  aria-label={selectCountryLabel ?? 'Toggle Market'}
                  onClick={toggleMarketPicker}
                  className={styles['market-picker-toggler']}
                >
                  <div className={styles['toggler-text-container']}>
                    <span>{marketName}</span>
                    <span>{marketName}</span>
                  </div>
                  <div className={styles['triangle']} />
                </button>
              </div>
            )}
          </GridChild>
        </Grid>
      </div>
    </footer>
  );
};

import { useLayoutEffect, useRef } from 'react';

import CloseSvg from '../../svg/close.svg';
import { SlideOutPosition } from '../slide-out/slide-out';

import { CloseButton, Container } from './slide-out-header.styled';

interface SlideOutHeaderProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  closeLabel: string;
  closeIcon?: React.ReactNode;
  focusReturn?: React.RefObject<HTMLElement | null>;
  position?: SlideOutPosition;
}

export const SlideOutHeader: React.FC<SlideOutHeaderProps> = ({
  children,
  onClick,
  closeLabel,
  closeIcon,
  focusReturn,
  position = 'inline-end',
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  let icon: React.ReactNode = (
    <CloseSvg aria-hidden={true} focusable={false} width={24} height={24} />
  );
  if (closeIcon) {
    icon = closeIcon;
  }
  useLayoutEffect(() => {
    const focusNew = buttonRef.current;
    const focusOld = focusReturn?.current;

    focusNew?.focus();

    return () => {
      if (focusOld) {
        focusOld.focus();
      }
      if (!focusOld && focusNew) {
        focusNew.blur();
      }
    };
  }, []);

  return (
    <Container $position={position}>
      {children}
      <CloseButton ref={buttonRef} onClick={onClick} aria-label={closeLabel}>
        {icon}
      </CloseButton>
    </Container>
  );
};

export type SlideOutHeaderElement = React.ReactElement<SlideOutHeaderProps>;

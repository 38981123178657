import { m } from 'framer-motion';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/shared/constants';

export type SlideOutPosition =
  | 'inline-start'
  | 'inline-end'
  | 'block-start'
  | 'block-end';

interface PositionProps {
  $position?: SlideOutPosition;
}

export const Backdrop = styled(m.div)`
  position: fixed;
  z-index: 20;
  inset: 0;
  transform: translate3d(0, 0, 0);
`;

export const Inner = styled(m.aside)<PositionProps>`
  display: grid;
  grid-template-rows: var(--header-height, 64px) 1fr;
  background-color: ${colors.white};
  position: absolute;
  z-index: 1;
  inline-size: 100%;
  max-inline-size: 428px; /* iPhone Pro Max */
  inset: 0 0 0 auto;
  inset-block: 0;
  inset-inline: auto 0;

  ${({ $position: position }) =>
    position === 'inline-start' &&
    css`
      inset-inline: 0 auto;
    `}
`;

export const Body = styled.div`
  position: relative;
  padding: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

import React from 'react';

import { CheckIcon } from '@radix-ui/react-icons';
import * as RadixSelect from '@radix-ui/react-select';
import clsx from 'clsx';

import styles from './select-item.module.scss';

import type { SelectItemProps as RadixSelectItemProps } from '@radix-ui/react-select';

interface SelectItemProps
  extends RadixSelectItemProps,
    React.HTMLAttributes<HTMLDivElement> {}

export const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, value, disabled, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={clsx(styles['item'], className)}
        value={value}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className={styles['indicator']}>
          <CheckIcon />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  },
);

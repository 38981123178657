import React from 'react';

import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';

import styles from './navigation-menu.module.scss';

import type { NavigationMenuProps } from '@radix-ui/react-navigation-menu';
export const NavigationMenu: React.FC<NavigationMenuProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <RadixNavigationMenu.Root
      className={clsx(styles['root'], className)}
      {...props}
    >
      <RadixNavigationMenu.List className={styles['navigation-menu-list']}>
        {children}
      </RadixNavigationMenu.List>
      <div className={styles['viewport-position']}>
        <RadixNavigationMenu.Viewport
          onPointerEnter={(e) => e.preventDefault()}
          onPointerLeave={(e) => e.preventDefault()}
          className={styles['navigation-menu-viewport']}
        />
      </div>
    </RadixNavigationMenu.Root>
  );
};

import React from 'react';

import {
  NavigationMenuContentProps,
  NavigationMenuLinkProps,
  NavigationMenuTriggerProps,
  NavigationMenuItemProps as RadixNavigationMenuItemProps,
} from '@radix-ui/react-navigation-menu';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import Link from 'next/link';

import sharedStyles from '../../style/shared.module.scss';

import styles from './navigation-menu-item.module.scss';

export interface NavigationMenuItemClassNames {
  item?: string;
  trigger?: string;
  content?: string;
  link?: string;
  activeLink?: string;
}

interface NavigationMenuItemProps extends RadixNavigationMenuItemProps {
  triggerLabel?: React.ReactNode;
  href: string;
  classNames?: NavigationMenuItemClassNames;
  subLinks?: boolean;
  active?: boolean;
}

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
  className,
  classNames,
  children,
  triggerLabel,
  subLinks,
  href,
  active,
  ...props
}) => {
  return (
    <RadixNavigationMenu.Item
      className={(clsx(styles['root']), className, classNames?.item)}
      {...props}
    >
      {subLinks ? (
        <>
          <NavigationMenuTrigger
            className={clsx(
              classNames?.trigger,
              active && classNames?.activeLink,
              sharedStyles['button-reset'],
              styles['navigation-menu-trigger'],
            )}
            onPointerMove={(e) => e.preventDefault()}
            onPointerLeave={(e) => e.preventDefault()}
          >
            {triggerLabel}
          </NavigationMenuTrigger>
          <NavigationMenuContent className={classNames?.content}>
            <ul className={styles['navigation-menu-content-list']}>
              {children}
            </ul>
          </NavigationMenuContent>
        </>
      ) : (
        <NavigationMenuLink
          className={clsx(classNames?.link, active && classNames?.activeLink)}
          href={href}
          active={active}
          asChild
        >
          {children}
        </NavigationMenuLink>
      )}
    </RadixNavigationMenu.Item>
  );
};

const NavigationMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  NavigationMenuTriggerProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <RadixNavigationMenu.Trigger
      className={clsx(styles['navigation-menu-trigger'], className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadixNavigationMenu.Trigger>
  );
});

const NavigationMenuContent = React.forwardRef<
  HTMLDivElement,
  NavigationMenuContentProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <RadixNavigationMenu.Content
      className={clsx(styles['navigation-menu-content'], className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadixNavigationMenu.Content>
  );
});

export const NavigationMenuLink = React.forwardRef<
  HTMLAnchorElement,
  NavigationMenuLinkProps
>(({ href, children, className, asChild, active, ...props }, forwardedRef) => {
  return (
    <RadixNavigationMenu.Link
      className={clsx(styles['navigation-menu-link'], className)}
      ref={forwardedRef}
      href={href}
      asChild={asChild}
      active={active}
      {...props}
    >
      <Link href={href ?? ''}>{children}</Link>
    </RadixNavigationMenu.Link>
  );
});

import styled, { css } from 'styled-components';

import { colors } from '@hultafors/shared/constants';

import { SlideOutPosition } from '../slide-out/slide-out';

interface ContainerProps {
  $position?: SlideOutPosition;
}

export const Container = styled.header<ContainerProps>`
  border-block-end: thin solid ${colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem 0;
  padding-block: 8px;
  ${({ $position }) =>
    $position === 'inline-start' &&
    css`
      padding-inline: 0 1rem;
      flex-direction: row-reverse;
    `}
`;

export const CloseButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  border-radius: 0%;
  cursor: pointer;
  min-block-size: var(--min-tap-size, 48px);
  min-inline-size: var(--min-tap-size, 48px);
  padding: 0;
  margin-block: 0;
  margin-inline: 0.5rem;
`;

import clsx from 'clsx';

import styles from './loader.module.scss';

interface LoaderProps {
  className?: string;
}
export const Loader: React.FC<LoaderProps> = ({ className }) => {
  return (
    <div
      className={clsx(styles['container'], styles['show'], className)}
      aria-hidden
    >
      <span className={styles['loader']}></span>
    </div>
  );
};

import { useId } from 'react';

import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';

import sharedStyles from '../../style/shared.module.scss';

import styles from './tabs.module.scss';

import type { TabsProps as RadixTabsProps } from '@radix-ui/react-tabs';

interface TabData {
  label: string;
  content: React.ReactNode;
}

interface TabsProps extends RadixTabsProps {
  tabs?: TabData[];
  className?: string;
  style?: React.CSSProperties;
  'aria-label'?: string;
}

const TAB_PREFIX = 'tab-';

export const Tabs: React.FC<TabsProps> = ({
  tabs = [],
  className,
  style,
  'aria-label': ariaLabel,
  defaultValue,
  ...props
}) => {
  const uid = useId();
  const firstTab = `${TAB_PREFIX}0`;
  const triggerMapper = (tab: TabData, index: number) => {
    const value = `${TAB_PREFIX}${index}`;
    const key = `Tabs-${uid}-${value}-trigger`;
    return (
      <RadixTabs.Trigger
        value={value}
        key={key}
        className={clsx(
          sharedStyles['button-reset'],
          styles['trigger'],
          className,
        )}
        style={style}
      >
        {tab.label}
      </RadixTabs.Trigger>
    );
  };
  const contentMapper = (tab: TabData, index: number) => {
    const value = `${TAB_PREFIX}${index}`;
    const key = `Tabs-${uid}-${value}-content`;
    return (
      <RadixTabs.Content
        value={value}
        key={key}
        className={styles['content']}
        style={style}
      >
        {tab.content}
      </RadixTabs.Content>
    );
  };

  if (!tabs?.length) {
    return null;
  }

  return (
    <RadixTabs.Root
      className={styles['root']}
      defaultValue={defaultValue || firstTab}
      {...props}
    >
      <RadixTabs.List className={styles['list']} aria-label={ariaLabel}>
        {tabs.map(triggerMapper)}
      </RadixTabs.List>
      {tabs.map(contentMapper)}
    </RadixTabs.Root>
  );
};

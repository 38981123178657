// These patterns are intentionally verbose for readability and capture groups
const hexPattern = /^#?(([a-f\d]{3})|([a-f\d]{6}));?$/i;

const rgbPattern =
  /^rgba?\((\d{1,3}),?\s*(\d{1,3}),?\s*(\d{1,3})(,\s*[\d.]+)?\);?$/i;

interface ColorRGB {
  r: number;
  g: number;
  b: number;
}

function colorToRgb(color: string): ColorRGB | null {
  if (hexPattern.test(color)) {
    return hexToRgb(color);
  }
  if (rgbPattern.test(color)) {
    return rbgToRgb(color);
  }

  return null;
}

function rbgToRgb(input: string): ColorRGB | null {
  if (!rgbPattern.test(input)) {
    return null;
  }
  const [r, g, b] = input
    .replace(hexPattern, '$1,$2,$3')
    .split(',')
    .map((value) => parseInt(value || '0', 16));
  if (
    typeof r === 'undefined' ||
    typeof g === 'undefined' ||
    typeof b === 'undefined'
  ) {
    return null;
  }
  return { b, g, r };
}

const hexToRgb = (input: string): ColorRGB | null => {
  if (!hexPattern.test(input)) {
    return null;
  }
  const [r, g, b] = input
    .replace(hexPattern, '$2,$3,$4,$5,$6,$7')
    .split(',')
    .filter(Boolean)
    .map((value) => parseInt(value, 16));
  if (
    typeof r === 'undefined' ||
    typeof g === 'undefined' ||
    typeof b === 'undefined'
  ) {
    return null;
  }
  return { b, g, r };
};

export function getLuminance(color: string): number {
  const rgb = colorToRgb(color);
  if (!rgb) {
    return -1;
  }
  return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
}

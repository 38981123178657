import clsx from 'clsx';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import styles from './grid-container-block.module.scss';
interface GridContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  gap?: boolean;
  fullwidth?: boolean;
}

export const GridContainer: React.FC<GridContainerProps> = ({
  children,
  className,
  gap,
  fullwidth,
  ...props
}) => {
  return (
    <div
      {...props}
      className={clsx(
        className,
        styles['grid-container-block'],
        gap && styles['grid-container-block__gap'],
        fullwidth && styles['grid-container-block__full-width'],
      )}
    >
      {children}
    </div>
  );
};

interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
  gap?: boolean;
}

export const GridRow: React.FC<GridRowProps> = ({
  children,
  gap,
  ...props
}) => {
  return (
    <Grid
      {...props}
      columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      columnGap={[
        { columnGap: 0 },
        { breakpoint: 'mobileMax', columnGap: gap ? 16 : 0 },
      ]}
      rowGap={gap ? 16 : 0}
      maxWidth="var(--grid-max-width)"
      margin="var(--grid-margin)"
    >
      {children}
    </Grid>
  );
};

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  columnSize: number;
}

export const GridItem: React.FC<GridItemProps> = ({
  children,
  columnSize,
  ...props
}) => {
  return (
    <GridChild
      {...props}
      columnSpan={[
        { columns: 12 },
        { breakpoint: 'mediumMobileMax', columns: 6 },
        { breakpoint: 'maxGridWidth', columns: columnSize },
      ]}
    >
      {children}
    </GridChild>
  );
};

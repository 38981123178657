import { HTMLAttributes } from 'react';

import { CSSProperties } from 'styled-components';

import { GridChildColumnSpanProp, GridChildStyled } from './grid-child.styled';

export interface GridChildProps {
  children?: React.ReactNode;
  className?: string;
  columnSpan?: GridChildColumnSpanProp;
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  padding?: CSSProperties['padding'];
  rowSpan?: string;
  rowStart?: string;
}

export const GridChild: React.FC<
  GridChildProps & HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className,
  columnSpan,
  align = 'stretch',
  padding = '',
  rowSpan = '',
  ...props
}) => {
  return (
    <GridChildStyled
      {...props}
      $columnSpan={columnSpan}
      $align={align}
      $padding={padding}
      $rowSpan={rowSpan}
      className={className}
    >
      {children}
    </GridChildStyled>
  );
};

import clsx from 'clsx';

import styles from './image-slider.module.scss';
import SwiperContent from './swiper-wrapper';

export interface ImageSliderProps {
  ariaLabelNext?: string;
  ariaLabelPrevious?: string;
  ariaLabelSliderNavigation?: string;
  children: React.ReactNode;
  className?: string;
}

export const ImageSliderShared: React.FC<ImageSliderProps> = ({
  ariaLabelNext,
  ariaLabelPrevious,
  ariaLabelSliderNavigation,
  children,
  className,
}) => {
  return (
    <div className={clsx(styles['slider-block'], className)}>
      <SwiperContent
        ariaLabelNext={ariaLabelNext}
        ariaLabelPrevious={ariaLabelPrevious}
        ariaLabelSliderNavigation={ariaLabelSliderNavigation}
      >
        {children}
      </SwiperContent>
    </div>
  );
};

import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './markdown.styled';

interface MarkdownProps {
  children?: string;
  options?: MarkdownToJSX.Options;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  options = {},
  className,
}) => {
  return (
    <StyledMarkdown
      options={{ forceBlock: true, ...options }}
      className={className}
    >
      {children ?? ''}
    </StyledMarkdown>
  );
};

import React from 'react';

import clsx from 'clsx';

import styles from './h-tag.module.scss';

export type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export type HTagStyleType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p1-preamble'
  | 'p2-paragraph'
  | 'p3-paragraph'
  | 'sub-heading';

interface HTagProps {
  type?: HTagType;
  styleType?: HTagStyleType;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  adjustStyles?: AdjustStyles;
}

interface AdjustStyles {
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  fontWeight?: React.CSSProperties['fontWeight'];
  fontFamily?: React.CSSProperties['fontFamily'];
  lineHeight?: React.CSSProperties['lineHeight'];
  letterSpacing?: React.CSSProperties['letterSpacing'];
  fontSize?: React.CSSProperties['fontSize'];
}

export const HTag: React.FC<HTagProps> = ({
  type = 'h1',
  className,
  styleType,
  children,
  adjustStyles,
}) => {
  const adjustedStyles = {
    ['--font-family' as string]: adjustStyles?.fontFamily,
    ['--font-size' as string]: adjustStyles?.fontSize,
    ['--font-weight' as string]: adjustStyles?.fontWeight,
    ['--letter-spacing' as string]: adjustStyles?.letterSpacing,
    ['--line-height' as string]: adjustStyles?.lineHeight,
    ['--text-align' as string]: adjustStyles?.textAlign,
    ['--text-color' as string]: adjustStyles?.color,
  };

  const HeadingTag = `${type}` as
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span';

  return (
    <HeadingTag
      className={clsx(
        styles['heading'],
        styleType
          ? styles[`heading__${styleType}`]
          : styles[`heading__${type}`],
        className,
      )}
      style={adjustedStyles}
    >
      {children}
    </HeadingTag>
  );
};

import React from 'react';

import clsx from 'clsx';

import {
  DatoImage as DatoImageT,
  getDatoImageProps,
} from '@hultafors/shared/helpers';
import { getImageLoadStyle } from '@hultafors/shared/helpers/get-image-load-style/get-image-load-style';

import { ImageLoadFragment as EripiowearImageLoadFragment } from '@hultafors/eripiowear/types';
import { ImageLoadFragment as HellbergImageLoadFragment } from '@hultafors/hellberg/types';
import { ImageLoadFragment as HultaforsImageLoadFragment } from '@hultafors/hultafors/types';
import { ImageLoadFragment as HultaforsgroupImageLoadFragment } from '@hultafors/hultaforsgroup/types';
import { ImageLoadFragment as SnickersImageLoadFragment } from '@hultafors/snickers/types';
import { ImageLoadFragment as SolidgearImageLoadFragment } from '@hultafors/solidgear/types';

import styles from './responsive-image.module.scss';

type ImageLoadFragment =
  | EripiowearImageLoadFragment
  | HellbergImageLoadFragment
  | HultaforsgroupImageLoadFragment
  | HultaforsImageLoadFragment
  | SnickersImageLoadFragment
  | SolidgearImageLoadFragment;

interface ResponsiveImageProps {
  alt?: string | null;
  aspectRatioLandscape?: number;
  aspectRatioPortrait?: number;
  className?: string;
  image?: ImageLoadFragment | null;
  isBackground?: boolean;
  maxSize?: number;
  priority?: boolean;
  sizesLandscape?: string;
  sizesPortrait?: string;
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  alt: altInput,
  aspectRatioLandscape = 21 / 9,
  aspectRatioPortrait = 3 / 4,
  className,
  image,
  isBackground,
  maxSize = 2880,
  priority,
  sizesLandscape = '100vw',
  sizesPortrait = '100vw',
}) => {
  let datoImage: DatoImageT | null = null;

  const style = getImageLoadStyle<ImageLoadFragment>(image, {
    '--aspect-ratio-landscape': aspectRatioLandscape.toString(),
    '--aspect-ratio-portrait': aspectRatioPortrait.toString(),
  });

  if (image?.url) {
    const initialProps = {
      aspectRatio: aspectRatioPortrait,
      aspectRatioLandscape,
      image,
      maxSize,
      priority: priority || false,
      sizes: sizesPortrait,
      sizesLandscape,
    };
    datoImage = getDatoImageProps(initialProps);
  }

  const alt = altInput || '';
  const loading = priority ? 'eager' : 'lazy';

  if (!image || !datoImage) return null;

  const { portrait, landscape } = datoImage;

  return (
    <figure
      style={style}
      className={clsx(
        className,
        styles['figure'],
        isBackground && styles['figure--background'],
      )}
      aria-hidden={isBackground}
    >
      {portrait && landscape && (
        <picture className={styles['wrapper']}>
          <source
            srcSet={portrait.srcSet}
            media="(orientation: portrait)"
            sizes={sizesPortrait}
          />
          <source
            srcSet={landscape.srcSet}
            media="(orientation: landscape)"
            sizes={sizesLandscape}
          />
          <img src={portrait.src} alt={alt} loading={loading} />
        </picture>
      )}
    </figure>
  );
};

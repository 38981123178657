import React from 'react';

import clsx from 'clsx';
import Image from 'next/image';

import {
  DatoImage as DatoImageT,
  getDatoImageProps,
} from '@hultafors/shared/helpers';
import { datoImageLoader } from '@hultafors/shared/helpers/dato-image-loader/dato-image-loader';
import { getImageLoadStyle } from '@hultafors/shared/helpers/get-image-load-style/get-image-load-style';

import { ImageLoadFragment as EripiowearImageLoadFragment } from '@hultafors/eripiowear/types';
import { ImageLoadFragment as HellbergImageLoadFragment } from '@hultafors/hellberg/types';
import { ImageLoadFragment as HultaforsImageLoadFragment } from '@hultafors/hultafors/types';
import { ImageLoadFragment as HultaforsgroupImageLoadFragment } from '@hultafors/hultaforsgroup/types';
import { ImageLoadFragment as SnickersImageLoadFragment } from '@hultafors/snickers/types';
import { ImageLoadFragment as SolidgearImageLoadFragment } from '@hultafors/solidgear/types';

import styles from './dato-image.module.scss';

type ImageLoadFragment =
  | EripiowearImageLoadFragment
  | HellbergImageLoadFragment
  | HultaforsgroupImageLoadFragment
  | HultaforsImageLoadFragment
  | SnickersImageLoadFragment
  | SolidgearImageLoadFragment;

export type DatoImageProps = {
  alt?: string;
  aspectRatio?: number;
  className?: React.CSSProperties;
  fill?: boolean;
  image: ImageLoadFragment | undefined | null;
  isBackground?: boolean;
  maxSize?: number;
  priority?: boolean;
  sizes?: string;
};

export const DatoImage: React.FC<DatoImageProps> = ({
  alt,
  aspectRatio = 16 / 9,
  className,
  fill,
  image,
  isBackground,
  maxSize = 2880,
  priority = false,
  sizes = '100vw',
}) => {
  let datoImage: DatoImageT | null = null;

  if (image?.url) {
    const initialProps = { aspectRatio, image, maxSize, priority, sizes };
    datoImage = getDatoImageProps(initialProps);
  }

  const style = getImageLoadStyle<ImageLoadFragment>(image, {
    '--aspect-ratio': aspectRatio.toString(),
  });

  if (!datoImage?.portrait || !image) return null;

  return (
    <figure
      style={style}
      className={clsx(
        className,
        styles['figure'],
        isBackground && styles['figure--background'],
      )}
      aria-hidden={isBackground}
    >
      <Image
        src={datoImage.portrait.src ?? ''}
        alt={alt || datoImage.portrait.alt || ''}
        fill={fill}
        blurDataURL={image.blurUpThumb || ''}
        placeholder={image.blurUpThumb ? 'blur' : 'empty'}
        sizes={sizes}
        loader={datoImageLoader}
        quality={85}
        height={!fill ? datoImage.portrait.height : undefined}
        width={!fill ? datoImage.portrait.width : undefined}
        priority={priority}
      />
    </figure>
  );
};

import { useEffect, useState } from 'react';

export const useLockBodyScroll = (active?: boolean): void => {
  const [originalStyle, setOriginalStyle] = useState<string>();
  useEffect(() => {
    // Get original body overflow
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      setOriginalStyle(window.getComputedStyle(document.body).overflow);
    }
    // Re-enable scrolling when component unmounts
    return () => {
      if (typeof document !== 'undefined') {
        document.body.style.overflow = originalStyle || '';
      }
    };
  }, []);

  useEffect(() => {
    // Toggle scrolling only when original style has been stored
    if (
      typeof document !== 'undefined' &&
      typeof originalStyle !== 'undefined'
    ) {
      document.body.style.overflow = active ? 'hidden' : originalStyle;
    }
  }, [originalStyle, active]);
};
